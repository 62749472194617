module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-theme-material-ui-top-layout@5.2.0_@mui+material@5.16.7_gatsby@5.14.0/node_modules/gatsby-theme-material-ui-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-material-ui@4.1.0_@emotion+react@11.13.3_gatsby@5.14.0/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-theme-i18n@3.0.0_gatsby-plugin-react-helmet@6.14.0_gatsby@5.14.0_react-dom@18.3.1_react-helmet@6.1.0_react@18.3.1/node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"prefixDefault":true,"defaultLang":"es","configPath":"/home/user/workspace/packages/site/i18n/config.json"},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-theme-i18n-react-i18next@3.0.0_gatsby@5.14.0_i18next@23.16.5_react-dom@18.3.1_react-i18next@13.5.0_react@18.3.1/node_modules/gatsby-theme-i18n-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"locales":"./i18n/react-i18next","i18nextOptions":{"fallbackLng":["en","es"],"ns":["common"]},"order":["path","querystring","cookie","localStorage","sessionStorage","navigator","htmlTag","subdomain"]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_graphql@16.9.0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Apartamentos Javier","short_name":"Apartamentos Javier","start_url":"/","icon":"src/images/apartamentosjavier_favicon_web_square.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9566f6c1b340fd26220a989c8235b186"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_react-dom@18.3.1_react@18.3.1_typescript@5.6.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
